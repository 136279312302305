import React, { useEffect } from "react"
import { initGoogleTagManager } from "@/lib/google-tag-manager"
import { useAppContext } from "@/lib/contexts/appContext"
import { getUserTokenWithContext } from "@/lib/helpers/user"
import fetchPathData from "@/lib/api/fetchPathData"
import { Layout } from "@/components/base/layout"
import Head from "@/lib/head"

const Index = ({ pathData }) => {
  const { meta, resetAppContext } = useAppContext()

  useEffect(() => {
    const { gtmContainerId } = meta
    if (gtmContainerId) {
      initGoogleTagManager(gtmContainerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    resetAppContext()

    const nextRoute = {
      event: "nextRoute",
      path: pathData.path
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(nextRoute)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData])

  return (
    <>
      <Head />
      <Layout />
    </>
  )
}

export async function getServerSideProps(context) {
  const token = getUserTokenWithContext(context)

  if (!token && !context.preview) {
    context.res.setHeader(
      "Cache-Control",
      "public, max-age=30, stale-while-revalidate=60"
    )
  }

  const path = context?.resolvedUrl ?? "/"
  const subDomain =
    typeof context?.req?.headers?.host === "string"
      ? context.req.headers.host.split(".")[0]
      : null

  if (context.preview) {
    return {
      redirect: {
        destination: "/api/stopPreviewSession" + path
      }
    }
  }

  const pathData = await fetchPathData(
    {
      path,
      context: subDomain,
      isPreview: context.preview && path === context?.previewData?.path
    },
    token
  )

  if (!pathData) {
    throw new Error("API response error")
  }

  if (pathData.status === 404) {
    context.res.statusCode = 404
  }

  if (pathData.redirect) {
    return {
      redirect: {
        destination: pathData.path,
        permanent: [301, 308].includes(pathData.status)
      }
    }
  }

  return {
    props: { pathData, context: subDomain }
  }
}

export default Index
